.btn-add {
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 10px;
  }
  .dropdown-add {
    margin-top: 45px !important;
    margin-left: 10px !important;
    z-index: 10;
  }
  .btn-save {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
  }
  .intz-node {
    height: 100%;
    border: 2px solid #a6b0cf;
    padding: 10px;
    border-radius: 5px;
    background: #32394f;
  }
  .intz-node-deleted {
    color: #3a3d46;
    border: 2px solid #3d424d !important;
    background: transparent !important;
  }
  
  .intz-node label {
    display: block;
    color: #a6b0cf;
    font-size: 12px;
  }
  
  .intz-group-node {
    background: transparent;
  }